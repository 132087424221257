import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
 
    getUsers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/v1/get-all-users")
          .then((response) => {
         
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/information_manager/role_management/roles`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
 
    fetchData(ctx,queryParams) {
      return new Promise((resolve, reject) => {

        // let url=`/api/v1/service_maintainer/persons?gender=${queryParams.gender}&min_service_registration_date=${queryParams.minDate}&max_service_registration_date=${queryParams.maxDate}`
       //console.log(queryParams)
        axios
          .get("/api/v1/achievement_target_stats", { params: queryParams })
          .then((response) => {

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
